import { Timestamp } from "./timestamp";
import { SquareItem } from "./squareItem";
import { ContactInformation } from "./contactinformation";

export class User {
	email: string = "";
	role: string = "";
	username: string = "";
	password: string = "";
	firstName: string = "";
	lastName: string = "";
	companyName: string = "";
	uid: string = "";
	profilePicture: string = "";
	profilePictureUid: string = "";
	profilePictureThumb: string = "";
	profilePictureThumb1: string = "";
	contactInformation: ContactInformation = new ContactInformation();
	dateCreated: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
	dateModified: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
	emailVerified: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
	checkInDate: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
	active: boolean = false;
	activeSubscription: boolean = false;
	currentTimeZone: string = "";
	currentTimeZoneOffset: number = 0;
	last4: string = "";
	cardBrand: string = "";
	expMonth: string = "";
	expYear: string = "";
	defaultCardId: string = "";
	squareUserId: string = "";
	shoppingCart: SquareItem = null;
	agreedToTerms: boolean = false;
	subscriptionPlanId: string = "OMNFP6VKBRAWG6SCDOA32EGE";
	squareSubscriptionId: string = "";
	squareSubPlanFirebaseUid: string = "";
	flaggerInstanceId: string = "";
	isRider: boolean = true;
	isTrackOwner: boolean = false;
	lastUsedTrackId: string = "";
	sponsoredTrackId: string = "";
	subscriptionExpiration: Timestamp = null;
	bike: string = "";
	riderNumber: string = "";
	checkInStr: string = "";
	hasTrialed: boolean = false;
	trialExpiration: Timestamp = null;

	constructor() {}

	buildUser(user: User) {
		for (var key in user) {
			if (user.hasOwnProperty(key)) {
				switch (key) {
					default:
						if (user[key] && user[key].getTime) {
							this[key] = new Timestamp(user[key].getTime() / 1000, 0);
						} else {
							this[key] = user[key] != undefined ? user[key] : null;
						}
						break;
				}
			}
		}
		return this;
	}

	toObj() {
		return {
			email: this.email,
			role: this.role,
			username: this.username,
			firstName: this.firstName,
			lastName: this.lastName,
			companyName: this.companyName,
			uid: this.uid,
			profilePicture: this.profilePicture,
			profilePictureUid: this.profilePictureUid,
			profilePictureThumb: this.profilePictureThumb,
			profilePictureThumb1: this.profilePictureThumb1,
			contactInformation:
				this.contactInformation.toObj === undefined
					? this.contactInformation
					: this.contactInformation.toObj(),
			dateCreated:
				this.dateCreated != null &&
				typeof this.dateCreated.toDate !== "undefined"
					? this.dateCreated.toDate()
					: this.dateCreated,
			dateModified:
				this.dateModified != null &&
				typeof this.dateModified.toDate !== "undefined"
					? this.dateModified.toDate()
					: this.dateModified,
			emailVerified:
				this.emailVerified != null &&
				typeof this.emailVerified.toDate !== "undefined"
					? this.emailVerified.toDate()
					: this.emailVerified,

			checkInDate:
				this.checkInDate != null &&
				typeof this.checkInDate.toDate !== "undefined"
					? this.checkInDate.toDate()
					: this.checkInDate,
			active: this.active,
			activeSubscription: this.activeSubscription,
			last4: this.last4,
			cardBrand: this.cardBrand,
			expMonth: this.expMonth,
			expYear: this.expYear,
			defaultCardId: this.defaultCardId,
			squareUserId: this.squareUserId,
			shoppingCart:
				this.shoppingCart?.toObj === undefined
					? this.shoppingCart
					: this.shoppingCart.toObj(),
			agreedToTerms: this.agreedToTerms,
			subscriptionPlanId: this.subscriptionPlanId,
			squareSubscriptionId: this.squareSubscriptionId,
			squareSubPlanFirebaseUid: this.squareSubPlanFirebaseUid,
			flaggerInstanceId: this.flaggerInstanceId,
			isRider: this.isRider,
			isTrackOwner: this.isTrackOwner,
			lastUsedTrackId: this.lastUsedTrackId,
			sponsoredTrackId: this.sponsoredTrackId,
			subscriptionExpiration:
				this.subscriptionExpiration != null &&
				typeof this.subscriptionExpiration.toDate !== "undefined"
					? this.subscriptionExpiration.toDate()
					: this.subscriptionExpiration,
			bike: this.bike,
			checkInStr: this.checkInStr,
			riderNumber: this.riderNumber,
			hasTrialed: this.hasTrialed,
			trialExpiration:
				this.trialExpiration != null &&
				typeof this.trialExpiration.toDate !== "undefined"
					? this.trialExpiration.toDate()
					: this.trialExpiration,
		};
	}
}
