import { Routes } from "@angular/router";
import { InternalAuthGuardService } from "./guards/internal-auth-guard.service";
import { ActiveSubAuthGuardService } from "./guards/active-sub-auth-guard.service";

export const routes: Routes = [
	{
		path: "",
		redirectTo: "landing",
		pathMatch: "full",
	},
	{
		path: "",
		loadComponent: () =>
			import("./landing/landing.page").then((m) => m.LandingPage),
	},
	{
		path: "owner",
		loadComponent: () => import("./owner/owner.page").then((m) => m.OwnerPage),
	},
	{
		path: "pricing",
		loadComponent: () =>
			import("./pricing/pricing.page").then((m) => m.PricingPage),
	},
	{
		path: "features",
		loadComponent: () =>
			import("./features/features.page").then((m) => m.FeaturesPage),
	},
	{
		path: "merch",
		loadComponent: () => import("./merch/merch.page").then((m) => m.MerchPage),
	},
	{
		path: "owner/pricing",
		loadComponent: () =>
			import("./pricing/pricing.page").then((m) => m.PricingPage),
	},
	{
		path: "owner/roi",
		loadComponent: () =>
			import("./roi-calc/roi-calc.page").then((m) => m.RoiPage),
	},
	{
		path: "eula",
		loadComponent: () => import("./eula/eula.page").then((m) => m.EulaPage),
	},
	{
		path: "login",
		loadComponent: () =>
			import("./core/login/pages/login/login.page").then((m) => m.LoginPage),
	},
	/// behind login
	{
		path: "dashboard",
		loadComponent: () =>
			import("./dashboard/dashboard.page").then((m) => m.DashboardPage),
		canActivate: [InternalAuthGuardService],
	},
	{
		path: "race-results",
		loadComponent: () =>
			import("./race-results/race-results.page").then((m) => m.RaceResultsPage),
		canActivate: [InternalAuthGuardService],
	},
	{
		path: "live-stream",
		loadComponent: () =>
			import("./live-stream/live-stream.page").then((m) => m.LiveStreamPage),
		canActivate: [ActiveSubAuthGuardService],
	},
	{
		path: "lap-times",
		loadComponent: () =>
			import("./lap-times/lap-times.page").then((m) => m.LapTimesPage),
		canActivate: [ActiveSubAuthGuardService],
	},
	{
		path: "cart",
		loadComponent: () => import("./cart/cart.page").then((m) => m.CartPage),
		canActivate: [InternalAuthGuardService],
	},
	{
		path: "video-clips",
		loadComponent: () =>
			import("./video-clips/video-clips.page").then((m) => m.VideoClipsPage),
		canActivate: [ActiveSubAuthGuardService],
	},
	{
		path: "payment-settings",
		loadComponent: () =>
			import("./payment-settings/payment-settings.page").then(
				(m) => m.PaymentSettingsPage
			),
		canActivate: [InternalAuthGuardService],
	},
	{
		path: "order-status",
		loadComponent: () =>
			import("./order-status/order-status.page").then((m) => m.OrderStatusPage),
		canActivate: [InternalAuthGuardService],
	},
];
