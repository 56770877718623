import { Injectable } from "@angular/core";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import { NavController } from "@ionic/angular";
import { UserService } from "./user.service";
import { MenuItem } from "../models/menuItem";

@Injectable({
	providedIn: "root",
})
export class MenuService {
	public scrollToViewId: string = "";
	public isOwner: boolean = false;
	public isRider: boolean = true;
	constructor(public navCtrl: NavController, public userService: UserService) {}

	async scroll(to: string) {
		this.scrollToViewId = to;
		let naved = await this.navCtrl.navigateRoot("");
		if (naved === null) {
			this.scrollToView();
		}
	}

	public menuItems(): MenuItem[] {
		let menu: MenuItem[] = [
			new MenuItem("Rider", "", false),
			new MenuItem("Owner", "owner", false),
			new MenuItem("Hardware", "hardware", false),
		];
		if (this.userService.loggedIn) {
			menu.push(new MenuItem("Account", "dashboard", false));
			menu.push(new MenuItem("Race Results", "race-results", false));

			if (this.userService.isLapsUnlocked()) {
				menu.push(new MenuItem("Lap Times", "lap-times", false));
			}
			if (this.userService.isLiveStreamUnlocked()) {
				menu.push(new MenuItem("Live Stream", "live-stream", false));
			}
			if (this.userService.isVideoClipsUnlocked()) {
				// menu.push(new MenuItem("Video Clips", "video-clips", false));
			}
		} else {
			menu.push(new MenuItem("Login", "login", false));
		}
		return menu;
	}
	public ownersMenu(): MenuItem[] {
		let menu: MenuItem[] = [
			new MenuItem("Pricing", "owner/pricing", false),
			new MenuItem("Track ROI", "owner/roi", false),
		];
		return menu;
	}
	public ridersMenu(): MenuItem[] {
		let menu: MenuItem[] = [
			new MenuItem("Pricing", "pricing", false),
			new MenuItem("Features", "features", false),
			new MenuItem("Merch", "merch", false),
		];
		return menu;
	}

	navigateTo(page: string) {
		if (page === "hardware") {
			this.openFlaggerStore();
			return;
		}
		if (page === "owner" || page === "owner/pricing" || page === "owner/roi") {
			this.isOwner = true;
			this.isRider = false;
		} else {
			this.isRider = true;
			this.isOwner = false;
		}
		if (page === "") {
			this.navCtrl.navigateRoot("");
		} else if (page != "") {
			this.navCtrl.navigateForward([page]);
		}
	}

	private openFlaggerStore() {
		const privacyUrl = "https://flaggerai.square.site/";
		window.open(privacyUrl, "_blank");
	}

	scrollToView() {
		try {
			var anchor = document.getElementById(this.scrollToViewId);
			if (anchor) {
				anchor.scrollIntoView({ block: "start", behavior: "smooth" });
				anchor.scrollTo();
			}
		} catch (error) {}
		this.scrollToViewId = "";
	}
}
