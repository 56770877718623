import { Timestamp } from './timestamp';

export class AgreedToEula {
  uid: string = '';
  userUid: string = '';
  dateCreated: Timestamp = new Timestamp(new Date().getTime() / 1000, 0);
  eulaVersion: string = '';

  constructor(userUid: string = '', eulaVersion: string = '') {
    this.userUid = userUid;
    this.eulaVersion = eulaVersion;
  }

  buildModel(model: AgreedToEula) {
    for (var key in model) {
      if (model.hasOwnProperty(key)) {
        if (model[key] && model[key].getTime) {
          this[key] = new Timestamp(model[key].getTime() / 1000, 0);
        } else {
          this[key] = model[key] != undefined ? model[key] : null;
        }
      }
    }
    return this;
  }

  buildObj(model: AgreedToEula) {
    for (var key in model) {
      if (model.hasOwnProperty(key)) {
        this[key] = model[key];
      }
    }
    return this.toObj();
  }

  toObj() {
    return {
      uid: this.uid,
      userUid: this.userUid,
      dateCreated:
        this.dateCreated != null &&
        typeof this.dateCreated.toDate !== 'undefined'
          ? this.dateCreated.toDate()
          : this.dateCreated,

      eulaVersion: this.eulaVersion,
    };
  }
}
